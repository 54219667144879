import styled, { css } from 'styled-components';
import Button from '~/components/_ui-kit/Button';
import { useEffect, useState } from 'react';
import { logClient } from '~/utils';
import { useLang } from '~/store/languageContext/hooks';
export interface CookieDialogProps {}

const COOKIES_STATE_KEY = 'MITO_CookiesDialog_user-accept';

type CookiesState = 'ACCEPTED' | 'REJECTED' | null;

const CookieDialog = ({}: CookieDialogProps) => {
  const [cookiesState, setCookiesState] = useState<CookiesState>(null);
  const [show, showDialog] = useState(false);
  const { t } = useLang();

  const writeCookiesState = (value: CookiesState) => {
    if (value) {
      localStorage.setItem(COOKIES_STATE_KEY, value);
      setCookiesState(value);
      showDialog(false);
    }
  };

  // Sincroniza el state con el localStorage
  useEffect(() => {
    const value = localStorage.getItem(COOKIES_STATE_KEY) as CookiesState;
    value ? writeCookiesState(value || null) : showDialog(true);
  }, []);

  return (
    <Root show={show}>
      <Box>
        <Content>
          <Text>
            {t('CookieModal:text')}{' '}
            <a href={t('link:privacy')} target="_blank">
              {t('CookieModal:link')}
            </a>
          </Text>
          <Buttons>
            <Button variant="secondary" onClick={() => writeCookiesState('REJECTED')}>
              {t('CookieModal:reject')}
            </Button>
            <Button onClick={() => writeCookiesState('ACCEPTED')}>
              {t('CookieModal:accept_cookies')}
            </Button>
          </Buttons>
        </Content>
      </Box>
    </Root>
  );
};

export default CookieDialog;

/** Styled components */

interface RootProps {
  show?: boolean;
}
const Root = styled.div<RootProps>`
  ${({ theme, show }) => css`
    position: fixed;
    font-size: 16px;
    bottom: 2em;
    left: 2em;
    right: 2em;
    max-width: 700px;
    z-index: 999;
    display: flex;
    pointer-events: none;
    ${show &&
    css`
      pointer-events: initial;
      & > * {
        opacity: 1 !important;
        transform: translateY(0%) !important;
      }
    `}
  `}
`;

const Box = styled.div`
  ${({ theme }) => css`
    border-radius: 16px;
    z-index: 1;
    color: ${theme.colors.textColor};
    background: linear-gradient(to right, ${theme.colors.highlight}, #ffffff);
    overflow: hidden;
    padding: 1px;
    width: 100%;
    transition: all 0.5s cubic-bezier(0.63, 0.01, 0, 1.03);
    transform: translateY(150%);
    opacity: 0;
    ${theme.device.mobile} {
      margin: 0 auto;
    }
  `}
`;

const Content = styled.div`
  ${({ theme }) => css`
    border-radius: 16px;
    content: '';
    display: block;
    background: ${theme.colors.backgroundStrong};
    padding: 1.5em;
    ${theme.device.tabletS} {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  `}
`;

const Text = styled.div`
  ${({ theme }) => css`
    font-size: 0.875em;
    a {
      color: ${theme.colors.highlight};
      text-decoration: underline;
    }
    ${theme.device.mobile} {
      margin-bottom: 1.5em;
    }
    ${theme.device.tabletS} {
      margin-right: 1.5em;
    }
  `}
`;

const Buttons = styled.div`
  ${({ theme }) => css`
    display: flex;
    & > * {
      width: max-content;
      :not(:last-child) {
        margin-right: 1em;
      }
    }
  `}
`;
